<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Section' : 'Create Page Section'" />
        <div>
          <md-button :to="{ name: 'Pages Section' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateSection">
          <md-field :class="getValidationClass('section_tag')">
            <label>Section Tag</label>
            <md-input
              v-model="form.section_tag"
              placeholder="E.g:about-left-section"
              required
            ></md-input>
            <span class="md-error" v-if="!$v.form.section_tag.required"
              >Section tag is required</span
            >
          </md-field>
          <md-field :class="getValidationClass('type')">
            <label for="movie">Section Type</label>
            <md-select v-model="form.type" name="type" id="type">
              <md-option
                v-for="(type, key) in formData.types"
                :key="type"
                :value="key"
                v-show="key == 'text'"
                required
                >{{ type }}</md-option
              >
            </md-select>
            <span class="md-error" v-if="!$v.form.type.required"
              >Section Type is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('page_id')">
            <label for="page_id">Page</label>
            <md-select v-model="form.page_id" name="page_id" id="page_id">
              <md-option
                v-for="(page, key) in formData.pages"
                :key="page"
                :value="key"
                required
                >{{ page }}</md-option
              >
            </md-select>
            <span class="md-error" v-if="!$v.form.page_id.required"
              >Page is required</span
            >
          </md-field>
          <div class="tiny-wrapper" v-show="form.type == 'text'">
            <tinymce id="d1" v-model="form.content" ref="tm"></tinymce>
          </div>

          <mdc-button :loading="loading">{{
            id ? "Update Section" : "Create Section"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("pages");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      content: "",
    },
    formData: {
      pages: [],
      types: [],
    },

    loading: false,
  }),
  validations: {
    form: {
      section_tag: {
        required,
      },
      type: {
        required,
      },
      page_id: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "createSection",
      "updateSection",
      "getSection",
      "getFormData",
    ]),
    async validateSection() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id
            ? this.updateSection(data)
            : this.createSection(data);
          let msg = this.id ? "Section Updated" : "Section Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Pages Section" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getSectionData() {
      if (this.id) {
        this.fetching = true;
        const { section } = await this.getSection(this.id);
        this.form = {
          id: section.id,
          section_tag: section.section_tag,
          type: section.type,
          content: section.content,
          page_id: section.page_id,
        };
        this.fetching = false;
      }
    },
    async getForm() {
      const data = await this.getFormData();

      this.formData = { ...data };
    },
  },
  mounted() {
    Promise.all([this.getForm(), this.getSectionData()]);
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.tiny-wrapper {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
